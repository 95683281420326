import { Component, OnInit, NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-bootstrap/carousel'; 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


 
export class LoginComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {

     
  }
 

}
