import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

// firstNameAutofilled: boolean;
//   lastNameAutofilled: boolean;
//   ageAutofilled: boolean;
//   emailAutofilled: boolean;
// mobilenumberAutofilled: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
